<template>
  <el-menu :default-active="activeIndex" mode="horizontal" @select="selectHandle" 
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b">
    <el-menu-item index="My">
      <template #title>
        <i class="el-icon-s-home"></i>
        <span v-if="activeIndex == 'My'">主页</span>
      </template>
    </el-menu-item>
    <el-menu-item index="MyGoods">
      <template #title>
        <i class="el-icon-s-cooperation"></i>
        <span v-show="activeIndex == 'MyGoods'">装备</span>
      </template>
    </el-menu-item>
    <el-menu-item index="Goods">
      <template #title>
        <i class="el-icon-s-goods"></i>
        <span v-show="activeIndex == 'Goods'">商店</span>
      </template>
    </el-menu-item>
    <div class="right-group">
      <el-menu-item index="Logout">
        <template #title>
          <i class="el-icon-switch-button"></i>
        </template>
      </el-menu-item>
    </div>
  </el-menu>
</template>

<script>
export default {
  name: "Header",
  setup() {
    return {
      activeIndex: 'My'
    };
  },
  created() {
    this.activeIndex = this.$route.name;
  },
  methods:{
    selectHandle(key){
      if(key == 'My'){
        this.activeIndex = 'My'
        this.$router.push({name: 'My'})
      }
      if(key == 'MyGoods'){
        this.activeIndex = 'MyGoods'
        this.$router.push({name: 'MyGoods'})
      }
      if(key == 'Goods'){
        this.activeIndex = 'Goods'
        this.$router.push({name: 'Goods'})
      }
      if(key == 'Logout'){
        this.activeIndex = 'Logout'
        this.$confirm('是否退出登录？', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            roundButton: true,
            buttonSize: 'large',
            center: true
          }).then(() => {
            this.$message({
              type: 'success',
              message: '已退出登录!'
            });
            window.sessionStorage.setItem('token', '')
            this.$router.push({name: 'Login'})
          });
      }
    }
  }
}
</script>

<style scoped>
[class ^= "el-icon-"]{
  color: #fff;
}
.right-group {
  float: right;
}
</style>