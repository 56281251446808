import axios from './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'
import router from './router'
import common from './common/common.js'
import animate from 'animate.css'

const filter = {
  /**
   * 格式化数字（9舍10入）
   * @param {*} num 数字
   * @param {*} fmt 保留的小数位
   * @returns 
   */
  formatNumber(num, fmt = 2){
    var fmtr = Math.pow(10, fmt);
    let unit = ''
    var k = 10000, sizes = ['', '万', '亿', '万亿'], i = 0
    if (num >= k) {
      i = Math.floor(Math.log(num) / Math.log(k))
      num = num / Math.pow(k, i)
    }
    unit = sizes[i];
    num = Math.floor(num * fmtr) / fmtr
    num = new Number(num).toFixed(fmt)
    return num + unit
  }
}

const app = createApp(App).use(animate).use(router)
installElementPlus(app)
app.config.globalProperties.common = common
app.config.globalProperties.axios = axios
app.config.globalProperties.$filter = filter
app.mount('#app')

