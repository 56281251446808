import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import My from '../views/home/My.vue'
import MyGoods from '../views/home/MyGoods.vue'
import Goods from '../views/home/Goods.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      index: 0
    },
    children: [
      {
        path: '',
        redirect: '/home/my'
      },
      {
        path: 'my',
        name: 'My',
        component: My,
        meta: {
          index: 11
        }
      },
      {
        path: 'mygoods',
        name: 'MyGoods',
        component: MyGoods,
        meta: {
          index: 12
        }
      },
      {
        path: 'goods',
        name: 'Goods',
        component: Goods,
        meta: {
          index: 13
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      index: 2
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      index: 1
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
