<template>
  <div class='registerDiv'>
    <el-form :model="loginUser" :rules="rules" ref="loginUser">
      <el-form-item label="账号" prop="name">
        <el-input placeholder="账号" type="text" v-model="loginUser.name"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input placeholder="密码" type="password" v-model="loginUser.password"></el-input>
      </el-form-item>
      <el-form-item label="再次输入" prop="checkPass">
        <el-input placeholder="密码" type="password" v-model="loginUser.checkPass"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="register('loginUser')">注册</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="goLogin()">已有账号？去登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else if(value.length < 6 || value.length > 20){
          callback(new Error('长度在6~20个字符'))
        } else {
          if (this.loginUser.checkPass !== '') {
            this.$refs.loginUser.validateField('checkPass');
          }
          callback();
        }
      };
    var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.loginUser.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      loginUser: {
        name: '',
        password: '',
        checkPass: ''
      },
      rules: {
        name:[
          {required: true, message: '请输入账号', trigger: 'blur'}
        ],
        password:[
          {required: true, validator: validatePass, trigger: 'blur'}
        ],
        checkPass:[
          {required: true, validator: validatePass2, trigger: 'blur'}
        ]
      },
    }
  },
  methods: {
    goLogin(){
      this.$router.push({name: 'Login'})
    },
    register(formName){
      this.$refs[formName].validate((valid) => {
        if(valid){
          this.axios
            .post('user/register', this.loginUser)
            .then(response => {
              if(response.data.code === '000000'){
                this.$message.success(response.data.data)
                this.$router.push({name: 'Login'})
              }
            });
        }else{
          return false;
        }
      })
    }
  }
};
</script>

<style scoped>
.registerDiv{
  padding: 80px 20px 0px 20px;
}
.el-button{
  width: 100%;
}
</style>