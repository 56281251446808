<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in"
      :enter-active-class="enterClass"
      :leave-active-class="leaveClass">
      <component class="animate__animated" :is="Component" />
    </transition>
  </router-view>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      enterClass: '',
      leaveClass: ''
    };
  },
  watch: {
    $route(to, from){
      if(to.meta.index >= from.meta.index){
        this.leaveClass='animate__backOutUp'
        this.enterClass='animate__backInUp'
      }else{
        this.leaveClass='animate__backOutDown'
        this.enterClass='animate__backInDown'
      }
    }
  }
}
</script>

<style lang="scss">
@import 'base-scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.el-header {
  padding: 0px !important;
}
.el-footer {
  padding: 0px !important;
}
.el-main {
  padding: 4px !important;
  width: 100% !important;
  height: 100% !important;
}
.el-container {
  height: 100% !important;
}
.el-message-box {
  width: 80% !important;
}
.addGoods-dialog .el-dialog__body{
  padding-bottom: 0px !important;
}
</style>
