<template>
  <table v-if="isTitle">
    <tr>
      <td class="attr-title"><strong>{{name}}</strong></td>
    </tr>
  </table>
  <table v-else>
    <tr>
      <td class="attr-name" :style="styleVar">{{name}}</td>
      <td class="attr-value"><slot></slot></td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'AttrBlock',
  props:{
    isTitle: {
      type: Boolean,
      default: false,
      required: false
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    nameWidth: {
      type: String,
      default: "50px",
      required: false
    }
  },
  computed: {
    styleVar() {
      return {
        '--name-width': this.nameWidth
      }
    }
  },
}
</script>

<style scoped>
table {
  width: 100%;
}
tr td {
  padding-block: 1%;
}
.attr-title {
  width: 100%;
  text-align: center;
}
.attr-name {
  width: var(--name-width);
  text-align: right;
}
</style>