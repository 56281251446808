<template>
  <div class="equipment-card" :style="styleVar">
    <div v-if="equipment == null" class="empty-div">
      <i class="el-icon-star-off" :style="styleVar"></i>
    </div>
    <el-popconfirm v-else title="卸下装备？" @confirm="unEquips()">
      <template #reference>
        <div style="width:100%;height:100%">
          <span class="e-name" :style="styleVar">{{equipment.name}}</span>
        </div>
      </template>
    </el-popconfirm>
  </div>
</template>

<script>
export default {
  name: 'Equipment',
  props:{
    equipment: {
      type: Object,
      default: null,
      required: false
    },
    size: {
      type: String,
      default: "54px",
      required: false
    }
  },
  computed: {
    styleVar() {
      return {
        '--size': this.size,
        '--color': this.common.colorsArray[this.equipment == null ? 0 : this.equipment.star]
      }
    }
  },
  methods: {
    unEquips(){
      if(this.equipment != null){
        this.axios.post('goods/unEquips?type='+this.equipment.type)
          .then(response => {
            if(response.data.code == '000000'){
              this.$message.success(response.data.data);
              this.$parent.$parent.sx();
            }else{
              this.$message.error(response.data.msg);
            }
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment-card {
  width: var(--size);
  height: var(--size);
  text-align: center;
  box-shadow: 0 2px 12px 2px var(--color);
}
.e-name {
  width: 100%;
  padding: 2px;
  line-height: 200%;
}
.empty-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-div > i {
  font-size: var(--size);
  opacity: 20%;
}
</style>