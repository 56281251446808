<template>
  <el-container>
    <el-main>
      <router-view v-slot="{ Component }">
        <transition mode="out-in"
          :enter-active-class="enterClass"
          :leave-active-class="leaveClass">
          <component class="animate__animated" :is="Component" />
        </transition>
      </router-view>
    </el-main>
    <el-footer><Header class="my-header"></Header></el-footer>
  </el-container>
</template>

<script>
import Header from "../components/Header.vue";

export default {
  name: "Home",
  components: {
    Header
  },
  data() {
    return {
      enterClass: '',
      leaveClass: ''
    };
  },
  watch: {
    $route(to, from){
      if(to.meta.index >= from.meta.index){
        this.leaveClass='animate__fadeOutLeft'
        this.enterClass='animate__fadeInRight'
      }else{
        this.leaveClass='animate__fadeOutRight'
        this.enterClass='animate__fadeInLeft'
      }
    }
  }
};
</script>
<style scoped>
.my-header {
  position: fixed !important;
  left: 0;
  bottom: 0;
  width: 100%;
}
.el-footer {
  height: 64px !important;
}
</style>