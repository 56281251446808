"use strict";

import axios from "axios";
import router from "../router";

let config = {
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  config => {
    config.headers.token = window.sessionStorage.getItem('token');
    return config;
  }
)

_axios.interceptors.response.use(
  response => {
    if(response.data.code){
      switch (response.data.code){
        case '000000':
          break;
        case '200001':
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
          break;
        default:
          console.log(response.data)
          console.log(response.data.msg)
      }
    }
    return response
  },
  error => {
    Promise.reject(error.response.status)
  }
)

export default _axios;
