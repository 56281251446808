const common = {
  oldView: false,
  goodsType: [
    {value:'helmet', text:'头盔'},
    {value:'necklace', text:'项链'},
    {value:'armor', text:'盔甲'},
    {value:'belt', text:'腰带'},
    {value:'ring', text:'戒指'},
    {value:'shoulders', text:'护肩'},
    {value:'gloves', text:'手套'},
    {value:'pants', text:'裤子'},
    {value:'boots', text:'鞋子'},
    {value:'bracelet', text:'手镯'},
    {value:'firstArms', text:'武器（主）'},
    {value:'secondArms', text:'武器（副）'}
  ],
  colors: {1:'#adb5bd', 2:'#748ffc', 3:'#da77f2', 4:'#ff80ab', 5:'#fe621f'},
  colorsArray: ['#dee2e6', '#adb5bd', '#748ffc', '#da77f2', '#ff80ab', '#fe621f'],
  texts: 	['普通', '强化', '稀有', '罕见', '史诗'],
  getGoodsTypeName(value){
    for(var i = 0; i < this.goodsType.length; i++){
      var item = this.goodsType[i]
      if (item.value == value) {
        return item.text
      }
    }
    return ''
  }
}

export default common