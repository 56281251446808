<template>
  <div class="root">
    <div class="my-card" v-loading="userInfo.loading">
      <AttrBlock name="我的数据" isTitle></AttrBlock>
      <AttrBlock name="姓名：">{{userInfo.name}}</AttrBlock>
      <AttrBlock name="等级：">
        <el-tag size="small" effect="dark">{{userInfo.level}}</el-tag>
      </AttrBlock>
      <AttrBlock name="经验：">{{$filter.formatNumber(userInfo.exp)}}</AttrBlock>
      <AttrBlock name="金币：">{{$filter.formatNumber(userInfo.money)}}</AttrBlock>
    </div>
    <div class="my-card" v-loading="waitProfit.loading">
      <AttrBlock name="待领取" isTitle></AttrBlock>
      <AttrBlock name="等级：">
        <el-tag size="small" effect="dark">{{waitProfit.level}}</el-tag>
      </AttrBlock>
      <AttrBlock name="进度：">
        <el-progress 
          :text-inside="true"
          :stroke-width="24"
          v-if="!isNaN(waitProfit.percentage)"
          :percentage="waitProfit.percentage"></el-progress>
      </AttrBlock>
      <AttrBlock name="经验：">{{$filter.formatNumber(waitProfit.exp)}}</AttrBlock>
      <AttrBlock name="金币：">{{$filter.formatNumber(waitProfit.money)}}</AttrBlock>
    </div>
    <div class="my-card" v-loading="curGain.loading">
      <AttrBlock name="成长" isTitle></AttrBlock>
      <AttrBlock name="基础：">
        <table style="width:100%">
          <tr>
            <td style="width:50%">{{$filter.formatNumber(curGain.secondAddMoney)}} 金币/秒</td>
            <td style="width:50%">{{$filter.formatNumber(curGain.secondAddExp)}} 经验/秒</td>
          </tr>
        </table>
      </AttrBlock>
      <AttrBlock name="点击：">
        <table style="width:100%">
          <tr>
            <td style="width:50%">{{$filter.formatNumber(curGain.clickAddMoney)}} 金币/次</td>
            <td style="width:50%">{{$filter.formatNumber(curGain.clickAddExp)}} 经验/次</td>
          </tr>
        </table>
      </AttrBlock>
    </div>
    <div class="my-card text-center">
      <el-button-group>
        <el-button @click="sx()">刷新数据</el-button>
        <el-button @click="zj()">增加收益</el-button>
        <el-button @click="lq()">领取收益</el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
import AttrBlock from '../../components/AttrBlock.vue'

export default {
  name: "My",
  components: {
    AttrBlock
  },
  data() {
    return {
      userInfo: {loading: true},
      waitProfit: {loading: true},
      curGain: {loading: true},
      timer: null
    }
  },
  created() {
    this.sx()
  },
  methods: {
    //刷新数据
    sx(){
      this.getUser()
      this.getWaitProfit()
      this.getCurGain()
      clearInterval(this.timer)
      this.timer = null
      this.setTimer()
    },
    //增加收益
    zj(){
      this.axios
        .post('profit/clickProfit')
        .then(response => {
          var profit = response.data.data;
          this.$message.success({
            message: '暴击倍率：'+profit.luck+'\n经验：'+profit.exp+'\n金币：'+profit.money,
            center: true,
            duration: 500
          });
          this.sx();
        })
    },
    //领取收益
    lq(){
      this.sx()
      this.axios
        .post('profit/getProfit')
        .then(response => {
          var profit = response.data.data;
          this.$message.success({
            message: '等级：'+profit.level+'\n经验：'+profit.exp+'\n金币：'+profit.money,
            center: true,
            duration: 500
          })
          this.sx()
        })
    },
    //获取用户数据
    getUser(){
      this.axios
        .get('user/info')
        .then(response => {
          this.userInfo = response.data.data
          if(this.userInfo == null){
            this.userInfo = new Object()
            this.userInfo.loading = false
          }
        })
    },
    //获取待领取收益
    getWaitProfit(){
      this.axios
        .get('profit/waitGetProfit')
        .then(response => {
          this.waitProfit = response.data.data
          if(this.waitProfit == null){
            this.waitProfit = new Object()
            this.waitProfit.loading = false
          }
        })
    },
    //获取增益属性
    getCurGain(){
      this.axios
        .get('profit/getCurGainDto')
        .then(response => {
          this.curGain = response.data.data
          if(this.curGain == null){
            this.curGain = new Object()
            this.curGain.loading = false
          }
        })
    },
    //定时执行
    setTimer(){
      if(this.timer == null){
        this.timer = setInterval(() => {
          this.autoView()
        }, 20)
      }
    },
    //自动计算显示属性
    autoView(){
      if(this.waitProfit === null){
        return
      }
      this.waitProfit.exp += this.curGain.secondAddExp/50
      this.waitProfit.money += this.curGain.secondAddMoney/50
      var curLevelTmp = this.userInfo.level
      var curLevelExp = this.levelExp(curLevelTmp)
      var totalExp = this.waitProfit.exp + this.userInfo.exp
      var expTmp = totalExp - curLevelExp
      var percentage = 0
      while(true){
        var nextLevelExp = this.levelExp(curLevelTmp + 1) - this.levelExp(curLevelTmp)
        if(expTmp >= nextLevelExp){
          curLevelTmp += 1
          expTmp -= nextLevelExp
        }else{
          percentage = Math.floor(expTmp * 100 / nextLevelExp)
          break
        }
      }
      this.waitProfit.percentage = percentage
      this.waitProfit.level = curLevelTmp - this.userInfo.level
    },
    //计算等级对应的经验值
    levelExp(level){
      level = level - 1
      if(level == 0){
        return 0
      }
      //EXP = 30 * (currentLevel^3 + 5 * currentLevel) - 80;
      var result = 30 * (level**3 + 5 * level) - 80
      return result
    }
  }
};
</script>

<style lang="scss" scoped>

</style>