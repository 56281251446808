<template>
  <div class='loginDiv'>
    <el-form :model="loginUser" :rules="rules" ref="loginUser">
      <el-form-item label="账号" prop="name">
        <el-input placeholder="账号" type="text" v-model="loginUser.name"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input placeholder="密码" type="password" v-model="loginUser.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login('loginUser')">登录</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="register()">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginUser: {
        name: '',
        password: ''
      },
      rules: {
        name:[
          {required: true, message: '请输入账号', trigger: 'blur'}
        ],
        password:[
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 20, message: '长度在6~20个字符', trigger: 'blur'}
        ]
      },
    }
  },
  methods: {
    login(formName){
      this.$refs[formName].validate((valid) => {
        if(valid){
          this.axios
            .post('user/login', this.loginUser)
            .then(response => {
              if(response.data.code === '000000'){
                window.sessionStorage.setItem('token', response.data.data)
                this.$router.push({name: 'My'})
              }else{
                this.$message.error(response.data.msg)
                window.sessionStorage.setItem('token', '')
              }
            });
        }else{
          return false;
        }
      })
    },
    register(){
      this.$router.push({name: 'Register'})
    }
  }
};
</script>

<style scoped>
.loginDiv{
  padding: 80px 20px 0px 20px;
}
.el-button{
  width: 100%;
}
</style>