<template>
  <div class="root">
    <el-button circle plain type="primary" size="mini" @click="changeView()" class="el-icon-sort change-view"></el-button>
    <el-button class="show_equipment_btn" size="mini" 
      @click="showEquipment = !showEquipment">
      <transition mode="out-in"
        leave-active-class="animate__animated animate__rotateOut"
        :duration="{ enter: 0, leave: 500 }">
        <i v-if="showEquipment" class="el-icon-caret-top"></i>
        <i v-else class="el-icon-caret-bottom"></i>
      </transition>
    </el-button>
    <div style="width:100%;min-height:28px">
    <el-collapse-transition>
      <div v-if="showEquipment" class="user-equipment">
        <Equipment id="helmet" :equipment="userEquipment.helmet"></Equipment>
        <Equipment id="necklace" :equipment="userEquipment.necklace"></Equipment>
        <Equipment id="armor" :equipment="userEquipment.armor"></Equipment>
        <Equipment id="belt" :equipment="userEquipment.belt"></Equipment>
        <Equipment id="ring" :equipment="userEquipment.ring"></Equipment>
        <Equipment id="shoulders" :equipment="userEquipment.shoulders"></Equipment>
        <Equipment id="gloves" :equipment="userEquipment.gloves"></Equipment>
        <Equipment id="pants" :equipment="userEquipment.pants"></Equipment>
        <Equipment id="boots" :equipment="userEquipment.boots"></Equipment>
        <Equipment id="bracelet" :equipment="userEquipment.bracelet"></Equipment>
        <Equipment id="firstArms" :equipment="userEquipment.firstArms"></Equipment>
        <Equipment id="secondArms" :equipment="userEquipment.secondArms"></Equipment>
      </div>
    </el-collapse-transition>
    </div>
    <transition mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
      <div v-if="oldView">
        <el-row v-if="goodsList.length > 0">
          <el-col :span="12" v-for="goods in goodsList" v-bind:key="goods">
            <div class="goods-card">
              <el-button circle type="danger" class="absolute-top-left" plain size="mini" @click="remove(goods.id)" icon="el-icon-delete"></el-button>
              <el-button circle type="success" class="absolute-top-right" plain size="mini" @click="equips(goods.id)">装</el-button>
              <AttrBlock :name="goods.name" isTitle class="color-primary"></AttrBlock>
              <AttrBlock name="" nameWidth="0px">
                <el-rate style="padding-top:10px;" v-model="goods.star"
                  :colors="common.colors" disabled disabled-void-color="#C6D1DE"
                  class="full-width text-center"></el-rate>
              </AttrBlock>
              <AttrBlock name="类型：" nameWidth="61px">{{common.getGoodsTypeName(goods.type)}}</AttrBlock>
              <AttrBlock name="金币增幅：" nameWidth="61px">{{$filter.formatNumber(goods.addMoney)}} /秒</AttrBlock>
              <AttrBlock name="经验增幅：" nameWidth="61px">{{$filter.formatNumber(goods.addExp)}} /秒</AttrBlock>
              <AttrBlock name="说明：" nameWidth="61px">
                <span v-if="new String(goods.describe).length < 6">{{goods.describe}}</span>
                <el-popover v-else
                    placement="bottom"
                    :width="200"
                    trigger="hover"
                    :content="goods.describe"
                  >
                    <template #reference>
                      <span class="color-primary">
                        {{new String(goods.describe).substr(0, 5) + '...'}}
                      </span>
                    </template>
                  </el-popover>
              </AttrBlock>
            </div>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="24"><el-empty description="还没有装备哦~"></el-empty></el-col>
        </el-row>
      </div>
      <div v-else>
        <el-table :data="goodsList" style="width: 100%" :row-class-name="tableRowClassName">
          <el-table-column type="expand">
            <template #default="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="商品">
                  <span>{{ props.row.name }}</span>
                </el-form-item>
                <el-form-item label="品质">
                  <el-rate style="padding-top:10px;" v-model="props.row.star"
                    :colors="common.colors" disabled disabled-void-color="#C6D1DE"
                    class="full-width text-center"></el-rate>
                </el-form-item>
                <el-form-item label="价格">
                  <span>{{ $filter.formatNumber(props.row.price) }}</span>
                </el-form-item>
                <el-form-item label="类型">
                  <span>{{ common.getGoodsTypeName(props.row.type) }}</span>
                </el-form-item>
                <el-form-item label="金币增幅">
                  <span>{{$filter.formatNumber(props.row.addMoney)}} /秒</span>
                </el-form-item>
                <el-form-item label="经验增幅">
                  <span>{{$filter.formatNumber(props.row.addExp)}} /秒</span>
                </el-form-item>
                <el-form-item label="说明">
                  <span v-if="new String(props.row.describe).length < 6">{{props.row.describe}}</span>
                  <el-popover v-else
                    placement="bottom"
                    :width="200"
                    trigger="hover"
                    :content="props.row.describe"
                  >
                    <template #reference>
                      <span class="color-primary">
                        {{new String(props.row.describe).substr(0, 5) + '...'}}
                      </span>
                    </template>
                  </el-popover>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品" 
            sortable show-overflow-tooltip
            :filters="common.goodsType"
            :filter-method="typeFilterHandler"></el-table-column>
          <el-table-column prop="price" label="价格" sortable>
            <template #default="scope">
              {{ $filter.formatNumber(scope.row.price) }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button circle type="danger" plain size="mini" @click="remove(scope.row.name)" icon="el-icon-delete"></el-button>
              <el-button circle type="success" plain size="mini" @click="equips(scope.row.id)">装</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </transition>
  </div>
</template>

<script>
import AttrBlock from '../../components/AttrBlock.vue'
import Equipment from '../../components/Equipment.vue'
export default {
  name: "MyGoods",
  components: {
    AttrBlock,
    Equipment
  },
  data() {
    return {
      goodsList: [],
      userEquipment: {},
      showEquipment: true,
      oldView: this.common.oldView
    }
  },
  created() {
    this.sx();
  },
  methods: {
    sx(){
      this.listMyGoods()
      this.getUserEquipment()
    },
    getUserEquipment(){
      this.axios.get('goods/getUserEquipment')
        .then(response => {
          this.userEquipment = response.data.data
        });
    },
    listMyGoods(){
      this.axios.get('goods/listMyGoods')
        .then(response => {
          this.goodsList = response.data.data
        });
    },
    equips(id){
      this.axios.post('goods/equips?id='+id)
        .then(response => {
          if(response.data.code == '000000'){
            this.$message.success(response.data.data);
            this.sx();
          }else{
            this.$message.error(response.data.msg);
          }
        });
    },
    remove(id){
      this.$confirm('确定删除？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          roundButton: true,
          buttonSize: 'large',
          center: true
        }).then(() => {
          this.axios.post('goods/removeMyGoods?id='+id)
            .then(response => {
              if(response.data.code == '000000'){
                this.$message.success(response.data.data);
                this.sx();
              }else{
                this.$message.error(response.data.msg);
              }
            });
        });
    },
    tableRowClassName(row) {
      return 'table-row-class-'+row.row.star
    },
    typeFilterHandler(value, row) {
      return row['type'] === value;
    },
    changeView(){
      this.common.oldView = !this.common.oldView
      this.oldView = this.common.oldView
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../base-scss';
.goods-card {
  @extend .my-card;
  font-size: 12px;
  position: relative;
}
.absolute-top-left {
  position: absolute;
  left: 8px;
  top: 4px;
}
.absolute-top-right {
  position: absolute;
  right: 8px;
  top: 4px;
}
$--equipment-size: 64px;
.user-equipment {
  font-size: 12px;
  margin: 2px;
  padding: 2px;
  position: relative;
  width: calc(100% - 8px);
  height: $--equipment-size * 5;
}
.equipment-left {
  position: absolute;
  left: 4px;
}
.equipment-right {
  position: absolute;
  right: 4px;
}
#helmet {
  @extend .equipment-left;
}
#necklace {
  @extend .equipment-left;
  top: $--equipment-size;
}
#armor {
  @extend .equipment-left;
  top: $--equipment-size * 2;

}
#belt {
  @extend .equipment-left;
  top: $--equipment-size * 3;

}
#ring {
  @extend .equipment-left;
  top: $--equipment-size * 4;

}
#shoulders {
  @extend .equipment-right;

}
#gloves {
  @extend .equipment-right;
  top: $--equipment-size;
  
}
#pants {
  @extend .equipment-right;
  top: $--equipment-size * 2;
  
}
#boots {
  @extend .equipment-right;
  top: $--equipment-size * 3;
  
}
#bracelet {
  @extend .equipment-right;
  top: $--equipment-size * 4;
  
}
#firstArms {
  position: absolute;
  left: $--equipment-size + 16px;
  top: $--equipment-size * 4;
}
#secondArms {
  position: absolute;
  right: $--equipment-size + 16px;
  top: $--equipment-size * 4;
}
.show_equipment_btn {
  position: fixed;
  z-index: 999;
  top: 0;
  left: calc(50% - 30px);
  width: 60px;
  text-align: center;
  height: 28px;
}
.change-view {
  position: fixed;
  z-index: 999;
  right: 4px;
  bottom: 66px;
}
</style>