<template>
  <div class="root" v-loading="loading">
    <el-button circle plain type="primary" size="mini" @click="changeView()" class="el-icon-sort change-view"></el-button>
    <div style="width:100%;height:42px;"></div>
    <el-row class="op-btn">
      <el-col :span="24" class="addGoods-dialog">
        <el-button @click="addDialogVisible = true" class="full-width">添加商品</el-button>
        <el-dialog title="添加商品" width="90%" center top="30px"
          v-model="addDialogVisible">
          <el-form ref="addGoods" :model="addGoods" 
            label-position="left" label-suffix=":" label-width="auto">
            <el-form-item label="名称">
              <el-input size="small" v-model="addGoods.name"></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-select size="small" class="full-width" v-model="addGoods.type" placeholder="请选择">
                <el-option
                  v-for="item in common.goodsType"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品质">
              <el-rate style="padding-top:10px;" v-model="addGoods.star"
                show-text :colors="common.colors" :texts="common.texts"></el-rate>
            </el-form-item>
            <el-form-item label="价格">
              <el-tooltip placement="top-start" content="建议：(经验增幅+金币增幅)×1000">
                <el-input size="small" type="number" min="0" v-model="addGoods.price"></el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="经验增幅">
              <el-tooltip placement="top-start" content="参考价格设置">
                <el-input size="small" type="number" v-model="addGoods.addExp"></el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="金币增幅">
              <el-tooltip placement="top-start" content="参考价格设置">
                <el-input size="small" type="number" v-model="addGoods.addMoney"></el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="说明">
              <el-input type="textarea" resize="none" v-model="addGoods.describe"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <el-row>
              <el-col :span="12">
                <el-button @click="addDialogVisible = false">取消</el-button>
              </el-col>
              <el-col :span="12">
                <el-button @click="addGoodsFun()" type="primary">添加</el-button>
              </el-col>
            </el-row>
          </template>
        </el-dialog>
      </el-col>
    </el-row>
    <transition mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
      <div v-if="oldView">
        <el-row v-if="goodsList.length > 0">
          <el-col :span="12" v-for="goods in goodsList" v-bind:key="goods">
            <div class="goods-card">
              <el-button circle type="danger" class="absolute-top-left" plain size="mini" @click="remove(goods.name)" icon="el-icon-delete"></el-button>
              <el-button circle type="success" class="absolute-top-right" plain size="mini" @click="buy(goods.name)">买</el-button>
              <AttrBlock :name="goods.name" isTitle class="color-primary"></AttrBlock>
              <AttrBlock name="" nameWidth="0px">
                <el-rate style="padding-top:10px;" v-model="goods.star"
                  :colors="common.colors" disabled disabled-void-color="#C6D1DE"
                  class="full-width text-center"></el-rate>
              </AttrBlock>
              <AttrBlock name="价格：" nameWidth="61px">{{$filter.formatNumber(goods.price)}}</AttrBlock>
              <AttrBlock name="类型：" nameWidth="61px">{{common.getGoodsTypeName(goods.type)}}</AttrBlock>
              <AttrBlock name="金币增幅：" nameWidth="61px">{{$filter.formatNumber(goods.addMoney)}} /{{goods.type == 'click' ? '次':'秒'}}</AttrBlock>
              <AttrBlock name="经验增幅：" nameWidth="61px">{{$filter.formatNumber(goods.addExp)}} /{{goods.type == 'click' ? '次':'秒'}}</AttrBlock>
              <AttrBlock name="说明：" nameWidth="61px">
                <span v-if="new String(goods.describe).length < 6">{{goods.describe}}</span>
                <el-popover v-else
                    placement="bottom"
                    :width="200"
                    trigger="hover"
                    :content="goods.describe"
                  >
                    <template #reference>
                      <span class="color-primary">
                        {{new String(goods.describe).substr(0, 5) + '...'}}
                      </span>
                    </template>
                  </el-popover>
              </AttrBlock>
            </div>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="24"><el-empty description="还没有商品哦~"></el-empty></el-col>
        </el-row>
      </div>
      <div v-else style="height:96%;">
        <el-table :data="goodsList"
          :row-class-name="tableRowClassName" height="100%">
          <el-table-column type="expand">
            <template #default="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="商品">
                  <span>{{ props.row.name }}</span>
                </el-form-item>
                <el-form-item label="品质">
                  <el-rate style="padding-top:10px;" v-model="props.row.star"
                    :colors="common.colors" disabled disabled-void-color="#C6D1DE"
                    class="full-width text-center"></el-rate>
                </el-form-item>
                <el-form-item label="价格">
                  <span>{{ $filter.formatNumber(props.row.price) }}</span>
                </el-form-item>
                <el-form-item label="类型">
                  <span>{{ common.getGoodsTypeName(props.row.type) }}</span>
                </el-form-item>
                <el-form-item label="金币增幅">
                  <span>{{$filter.formatNumber(props.row.addMoney)}} /秒</span>
                </el-form-item>
                <el-form-item label="经验增幅">
                  <span>{{$filter.formatNumber(props.row.addExp)}} /秒</span>
                </el-form-item>
                <el-form-item label="说明">
                  <span v-if="new String(props.row.describe).length < 6">{{props.row.describe}}</span>
                  <el-popover v-else
                    placement="bottom"
                    :width="200"
                    trigger="hover"
                    :content="props.row.describe"
                  >
                    <template #reference>
                      <span class="color-primary">
                        {{new String(props.row.describe).substr(0, 5) + '...'}}
                      </span>
                    </template>
                  </el-popover>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品" 
            sortable show-overflow-tooltip
            :filters="common.goodsType"
            :filter-method="typeFilterHandler"></el-table-column>
          <el-table-column prop="price" label="价格" sortable>
            <template #default="scope">
              {{ $filter.formatNumber(scope.row.price) }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button circle type="danger" plain size="mini" @click="remove(scope.row.name)" icon="el-icon-delete"></el-button>
              <el-button circle type="success" plain size="mini" @click="buy(scope.row.name)">买</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </transition>
  </div>
</template>

<script>
import AttrBlock from '../../components/AttrBlock.vue'

export default {
  name: "Goods",
  components: {
    AttrBlock
  },
  data() {
    return {
      addDialogVisible: false,
      addGoods: {
        name: ''
      },
      goodsList: [],
      loading: true,
      oldView: this.common.oldView
    }
  },
  created() {
    this.sx()
  },
  methods: {
    sx(){
      this.listGoods();
    },
    listGoods(){
      this.axios.get('goods/listGoods')
        .then(response => {
          this.goodsList = response.data.data
          this.loading = false
        });
    },
    addGoodsFun(){
      this.axios.post('goods/addGoods', this.addGoods)
        .then(response => {
          if(response.data.code == '000000'){
            this.$message.success({
              message: response.data.data,
              center: true,
              offset: 0,
              duration: 500
            });
            this.addDialogVisible = false;
            this.sx();
          }else{
            this.$message.error({
              message: response.data.msg,
              center: true,
              offset: 0,
              duration: 500
            });
          }
        });
    },
    buy(goodsName){
      this.$confirm('是否购买？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          roundButton: true,
          buttonSize: 'large',
          center: true
        }).then(() => {
          this.axios.post('goods/buyGoods?goodsName='+goodsName)
            .then(response => {
              if(response.data.code == '000000'){
                this.$message.success({
                  message: response.data.data,
                  center: true,
                  offset: 0,
                  duration: 500
                });
              }else{
                this.$message.error({
                  message: response.data.msg,
                  center: true,
                  offset: 0,
                  duration: 500
                });
              }
            });
        });
    },
    remove(goodsName){
      this.$confirm('确定删除？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          roundButton: true,
          buttonSize: 'large',
          center: true
        }).then(() => {
          this.axios.post('goods/removeGoods?goodsName='+goodsName)
            .then(response => {
              if(response.data.code == '000000'){
                this.$message.success(response.data.data);
                this.sx();
              }else{
                this.$message.error(response.data.msg);
              }
            });
        });
    },
    tableRowClassName(row) {
      return 'table-row-class-'+row.row.star
    },
    typeFilterHandler(value, row) {
      return row['type'] === value;
    },
    changeView(){
      this.common.oldView = !this.common.oldView
      this.oldView = this.common.oldView
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../base-scss';
.goods-card {
  @extend .my-card;
  font-size: 12px;
  position: relative;
}
.absolute-top-left {
  position: absolute;
  left: 8px;
  top: 4px;
}
.absolute-top-right {
  position: absolute;
  right: 8px;
  top: 4px;
}
.op-btn {
  position: fixed;
  top: 0;
  width: calc(100% - 8px);
  margin-top: 4px;
  z-index: 999;
}
.change-view {
  position: fixed;
  z-index: 999;
  right: 4px;
  bottom: 66px;
}
</style>